@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.header_new {
    /* background-color: gold; */
  }
.spin {
	background-color: transparent;
	-webkit-animation: Spin 2s infinite linear;
	        animation: Spin 2s infinite linear;
}
@-webkit-keyframes Spin {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
@keyframes Spin {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.menu-container {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	position: absolute;
	z-index: 1000;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0;
	font-size: 1rem;
	/* text-align: left; */
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}

.menu-item:hover {
	cursor: pointer;
	background: rgb(240, 240, 240);
}

.menu-item.active {
	background-color: #dc3545;
	border-color: #dc3545;
	color: white;
}
.menu-item {
	display: block;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	border: 0;
}
.menu-item-delete {
	color: #ff0000;
}
.menu-item-title {
	display: block;
	padding-top: 1rem;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	border: 0;
	font-weight: bold;
	text-decoration: underline;
}


.ck-editor__editable_inline {
	min-height: 200px;
}

body {
	 font-family: 'Exo', sans-serif; 
	/* direction: 'rtl'; */
}


