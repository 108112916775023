.menu-container {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	position: absolute;
	z-index: 1000;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0;
	font-size: 1rem;
	/* text-align: left; */
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}

.menu-item:hover {
	cursor: pointer;
	background: rgb(240, 240, 240);
}

.menu-item.active {
	background-color: #dc3545;
	border-color: #dc3545;
	color: white;
}
.menu-item {
	display: block;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	border: 0;
}
.menu-item-delete {
	color: #ff0000;
}
.menu-item-title {
	display: block;
	padding-top: 1rem;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	border: 0;
	font-weight: bold;
	text-decoration: underline;
}


.ck-editor__editable_inline {
	min-height: 200px;
}
