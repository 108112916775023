.spin {
	background-color: transparent;
	animation: Spin 2s infinite linear;
}
@keyframes Spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
